<template>
  <div class="insight-view-container" :class="containerClasses">
    <div class="insight-view-container__header" :class="containerClasses + '__header'">
      <div class="insight-view-container__header--left">
        <h1>Research</h1>
      </div>
      <div class="insight-view-container__header--right">
        <MyPopperWrapper arrow class="popper-container">
          <button class="request-btn" @click="openPopper">
            <i class="icon icon-add_plus"></i>
            Request an research source
          </button>
          <template #content v-if="visibleRequestSourceModal">
            <request-news-source :requestType="'research institute'"></request-news-source>
          </template>
        </MyPopperWrapper>
      </div>
    </div>
    <div v-if="activeSection[0].module_name === 'Trending News'" class="insight-view-container__body"
      :class="containerClasses + '__body'">
      <insight-view :activeSection="activeSection[0].module_name"> </insight-view>
    </div>
    <div v-else class="insight-view-container__body" :class="containerClasses + '__body'">
      <article-grid-view :activeSection="activeSection[0].module_name"> </article-grid-view>
    </div>
    <div class="insight-view-container__footer">
       <the-pagination
        @change-page="searchInsightsArticles" 
        :pageNumber="pageNumber"
        :totalPages="totalPages" 
        :results="insightsLength"
        @move-first-page="goToFirstPage"
        @move-last-page="goToLastPage">
      </the-pagination>
    </div>
  </div>
</template>

<script>
import InsightView from "./InsightView.vue";
import ArticleGridView from "./ArticleGridView.vue";
import RequestNewsSource from '../BaseComponents/RequestNewsSource.vue';
import MyPopperWrapper from "vue3-popper";
import ThePagination from "../BaseComponents/CustomPagination.vue";

export default {
  components: {
    InsightView,
    ArticleGridView,
    RequestNewsSource,
    MyPopperWrapper,
    ThePagination
  },
  props: {
    activeSection: Array
  },
  data() {
    return {
      visibleRequestSourceModal: false,
      openiscussionCollapse: ""
    };
  },
  // mounted() {
  //   this.emitter.on('openCollapseSection', (val) => {
  //     val ? this.openiscussionCollapse = ' pdb-discussion-collapse-opened' : this.openiscussionCollapse = '';
  // });
  // },
  computed: {
    containerClasses() {
      const value = this.activeSection.find((module) => {
        return module.module_name === 'Insights';
      });
      const isFirstOrder = value.order === 0;
      const dynamicClass = isFirstOrder ? 'left-pdb-section' : 'right-pdb-section';
      return dynamicClass;
    },
    results() {
      return {
        value: this.$store.getters["discover/getInsightsTotalResults"].value,
        relation: this.$store.getters["discover/getInsightsTotalResults"].relation,
      };
    },
    pageNumber() {
      return this.$store.getters["discover/getInisghtsCurrenPageNumber"];
    },
    totalPages() {
      if (this.results.value) {
        return Math.ceil(this.results.value / 9);
      }
      return 0;
    },
    insightsLength() {
      return this.$store.getters["discover/getInsightsTotalArticles"];
    },
    pageOffset: {
      get() {
        return this.$store.getters["discover/getInsightsPageOffset"];
      },
      set(val) {
        this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", val)
      }
    }
  },
  methods: {
    async searchInsightsArticles(page) {
      this.pageOffset = page === 'next' ? this.pageOffset + 10 : Math.max(0, this.pageOffset - 10);
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToFirstPage(){
      this.pageOffset = 0;
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToLastPage(){
      this.pageOffset = this.results.value - 2;
      console.log(this.results.value , " Hereee" , this.pageOffset)
      await this.$store.dispatch("discover/searchInsights");
    },
    openPopper() {
      this.visibleRequestSourceModal = true;
      this.emitter.emit('clearFields');
    },
  },
};
</script>

<style lang="scss">
.insight-view-container {
  border: 1px solid #d6d6d6;
  border-radius: 2rem;
  height: calc(100vh - 16.4rem);
  overflow: hidden;
  position: relative;

  @include respond(tab-port) {
    margin: 2rem 9px 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    cursor: grab;
    &--left {
      h1 {
        font-size: 2.4rem;
        line-height: 3.5rem;
        margin-bottom: 0;
        font-family: $font-primary-bold;
        position: relative;
        width: fit-content;
        @media (min-width: 1200px) and (max-width: 1300px) {
          font-size: 2.2rem;
          line-height: 3.3rem;
        }

        &::after {
          content: "";
          position: absolute;
          height: 3px;
          width: 6rem;
          background-color: $color-primary;
          left: 0;
          bottom: -0.5rem;
        }
      }
    }

    &--right {
      display: flex;
      align-items: center;

      .inline-block {
        border: 0 !important;
        margin: 0 !important;
        display: block;

        @include respond(phone-x-small) {
          display: none;
        }
      }
      .popper-container {
        position: relative;
        .popper {
          right: 0 !important;
          top: 100% !important;
          transform: translate(-41%, 2%) !important;
          cursor: default;
          @media only screen and (max-width: 768px) {
            right: 50% !important;
            transform: translate(-27%, 2%) !important;
          }
        }
      }
    }
  }

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    
    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
      height: 0.3rem;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    background-color: $color-white;
    width: 100%;
    padding: 1rem 2rem;
    .pagination-container {
      margin: 0 auto;
      width: fit-content;
      &__pagination {
        margin-top: 0;
      }
    }
  }
}

.right-pdb-section {
  margin: 0 9px 0 5px;
  @include respond(tab-port) {
    margin-bottom: 15rem !important;
  }
  @include respond(phone-x-small) {
    margin-bottom: 0 !important;
  }
  &__header {
    padding: 1.8rem 2rem;
    @include respond(laptop-large) {
      padding: 1.8rem 2rem !important;
    }
    @include respond(tab-port) {
      padding: 1.8rem 2.6rem !important;
    }
  }

  &__body {
    padding: 2.5rem 2rem;
    height: calc(100% - 12rem);
    @include respond(laptop-large) {
      padding: 2.2rem 2rem !important;
    }
    @include respond(tab-port) {
      padding: 2.2rem 2.6rem !important;
    }
  }
}

.left-pdb-section {
  margin: 0 5px 0 9px;
  &__header {
    padding: 1.8rem 2.6rem;
    @include respond(laptop-large) {
      padding: 1.8rem 2.6rem !important;
    }
  }

  &__body {
    padding: 2.5rem 2.6rem;
    height: calc(100% - 8rem);
    @include respond(laptop-large) {
      padding: 2.2rem 2.6rem !important;
    }
  }
}
// .pdb-discussion-collapse-opened {
//   height: calc(100vh - 22.4rem);
// }
</style>
