<template>
  <div class="tab-management-container">
    <div class="tab-management-container__section">
      <div class="tab-management-container__section--header">
        <h2 class="title">Change Name</h2>
      </div>
      <input type="text" class="tab-management-container__section--input" placeholder="kjkjk" v-model="topicName" name="" id="" />
    </div>
    <div class="tab-management-container__section">
      <div class="tab-management-container__section--header">
        <h2 class="title">Privacy Settings</h2>
      </div>
      <div class="privacy-settings">
        <div class="privacy-settings__header">
          <div class="privacy-settings__header--name">
            <img src="@/assets/icons/public-icon.svg" />
            <!-- <img src="@/assets/icons/private-icon.svg" /> -->
            <p>Public</p>
          </div>
          <!-- :class="{'public-topic-active': type === 'Public' && audienceChoice === 'public'}" -->
          <a-select ref="select" v-model:value="audienceChoice" class="privacy-settings__header--select">
            <a-select-option  value="public" @click.stop>
              <div class="select-item">
                <img
                  src="@/assets/icons/public-icon.svg"
                  style="width: 1.6rem; margin-right: 0.9rem;"
                  class="black-icon"
                />
                <img
                  src="@/assets/icons/public-icon-white.svg"
                  style="width: 1.6rem; margin-right: 0.9rem; display: none;"
                  class="white-icon"
                />Public
              </div>
            </a-select-option>
            <!-- :class="{'public-topic-active': type === 'Private' && audienceChoice === 'private'}" -->
            <a-select-option  value="private" @click.stop>
              <div  
              class="select-item">
                <img
                  src="@/assets/icons/private-icon.svg"
                  style="width: 1.6rem; margin-right: 0.9rem;"
                  class="black-icon"
                />
                <img
                  src="@/assets/icons/private-icon-white.svg"
                  style="width: 1.6rem; margin-right: 0.9rem; display: none;"
                  class="white-icon"
                />Private
              </div>
            </a-select-option>
          </a-select>
        </div>
        <!-- <div class="privacy-settings__body">
          <p>
            Public Topics are coming soon to Glocal.They will help you in
            opening public discussions to specific topics and get much better
            insights on them.
          </p>
        </div> -->
      </div>
    </div>
    <div class="tab-management-container__section">
      <div class="tab-management-container__section--header">
        <h2 class="title">Topic Admins</h2>
        <button @click="addAdmins()" class="btn"><i class="icon icon-add_plus"></i>Add Admin</button>
      </div>
      <p v-if="errorMsg" class="tab-management-container__section--msg">{{ errorMsg }}</p>
      <div class="tab-management-container__section--search-user">
        <TagifyContacts
          placeholder="Search"
          :options="contactOptions"
          @searchChange="fetchAllUsers"
          @fetchSelection="fetchedSelection"
          workingOn="connections"
          :isSubmitted="isSubmit"
          :id="randomId"
          :removeFields="emptyTagifyInput"
        ></TagifyContacts>
      </div>
      
      <div class="tab-management-container__section--active-admin">
        <div class="header">
          <h2>Active Admins {{ users.admins.length }}</h2>
        </div>
        <div class="body">
          <user-card
            v-for="admin in users.admins" 
            :key="admin"
            :fullName="admin.full_name"
            :profile="admin.profile_picture"
            :createdBy="users.created_by"
            :adminId="admin.id"
            :groupId="users.id"
            @removeUser="removeUser"
          ></user-card>
          <user-card
            v-for="admin in users.pending_invitations_admins" 
            :key="admin"
            :fullName="admin.full_name"
            :profile="admin.profile_picture"
            :createdBy="users.created_by"
            :adminId="admin.id"
            :groupId="users.id"
            :isPending="true"
            @removeUser="removeUser"
          ></user-card>
        </div>
      </div>
    </div>
    <div class="tab-management-container__section">
      <div class="tab-management-container__section--header">
        <h2 class="title">Topic Editors</h2>
        <button @click="addEditors()" class="btn"><i class="icon icon-add_plus"></i>Add Editors</button>
      </div>
      <div class="tab-management-container__section--search-user">
        <TagifyContacts
          placeholder="Search users"
          :options="contactOptions"
          @searchChange="fetchAllUsers"
          @fetchSelection="fetchedSelection"
          workingOn="connections"
          :isSubmitted="isSubmit"
          :id="randomId"
          :removeFields="emptyTagifyInput"
        ></TagifyContacts>
      </div>
      <div class="tab-management-container__section--active-admin">
        <div class="header">
          <h2>Active Editors {{ users.editors.length }}</h2>
        </div>
        <div class="body">
          <p v-if="users.editors.length == 0 && users.pending_invitations_editors.length == 0" class="message">No editors found</p>
          <user-card 
            v-for="editor in users.editors" 
            :key="editor"
            :fullName="editor.full_name"
            :profile="editor.profile_picture"
            :createdBy="users.created_by"
            :adminId="editor.id"
            :groupId="users.id"
            @removeUser="removeUser"
          ></user-card>
          <user-card 
            v-for="editor in users.pending_invitations_editors" 
            :key="editor"
            :fullName="editor.full_name"
            :profile="editor.profile_picture"
            :createdBy="users.created_by"
            :adminId="editor.id"
            :groupId="users.id"
            :isPending="true"
            @removeUser="removeUser"
          ></user-card>
        </div>
      </div>
    </div>
    <div class="tab-management-container__section">
      <div class="tab-management-container__section--header">
        <h2 class="title">Topic Contributors</h2>
        <button @click="addContributors()" class="btn contributors-btn"><i class="icon icon-add_plus"></i>Add Contributors</button>
      </div>
      <div class="tab-management-container__section--search-user">
        <TagifyContacts
          placeholder="Search users"
          :options="contactOptions"
          @searchChange="fetchAllUsers"
          @fetchSelection="fetchedSelection"
          workingOn="connections"
          :isSubmitted="isSubmit"
          :id="randomId"
          :removeFields="emptyTagifyInput"
        ></TagifyContacts>
      </div>
      <div class="tab-management-container__section--active-admin">
        <div class="header">
          <h2>Active Contributors {{ users.contributors.length }}</h2>
        </div>
        <div class="body">
          <p v-if="users.contributors.length == 0 && users.pending_invitations_contributors.length == 0" class="message">No contributors found</p>
          <user-card 
            v-for="editor in users.contributors" 
            :key="editor"
            :fullName="editor.full_name"
            :profile="editor.profile_picture"
            :createdBy="users.created_by"
            :adminId="editor.id"
            :groupId="users.id"
            @removeUser="removeUser"
          ></user-card>
          <user-card 
            v-for="editor in users.pending_invitations_contributors" 
            :key="editor"
            :fullName="editor.full_name"
            :profile="editor.profile_picture"
            :createdBy="users.created_by"
            :adminId="editor.id"
            :groupId="users.id"
            :isPending="true"
            @removeUser="removeUser"
          ></user-card>
        </div>
      </div>
    </div>
    <div class="tab-management-container__footer">
      <a-button :disabled="disableButton" :loading="loader" @click="sendInvitation" class="apply-btn">Apply Changes</a-button>
    </div>
  </div>
</template>

<script>
import TagifyContacts from "../../PdbComponents/TagifyAjax.vue";
import UserCard from "./UserCard.vue";


export default {
  components: {
    TagifyContacts,
    UserCard,
  },
  props: {
    topicToBeManage: Object
  },
  data() {
    return {
      audienceChoice: "Change Type",
      contactOptions: [],
      friends: [],
      emptyTagifyInput: false,
      isSubmit: false,
      selectedConnections: [],
      newEditors: [],
      newAdmins: [],
      newContributors:[],
      loader: false,
      errorMsg: "",
      topicName: ""
    }
  },
  mounted() {
    this.topicName = this.topicToBeManage.name
  },
  computed: {
    disableButton() {
      let state;
      if ((Object.keys(this.newAdmins).length == 0 &&
        Object.keys(this.newEditors).length == 0 && 
        Object.keys(this.newContributors).length == 0 )&&
        this.audienceChoice == 'Change Type' &&
        this.topicName === this.topicToBeManage.name) {
        state = true;
      }
      else {
        state = false;
      }
      return state;
    },
    topicDetails() {
      return this.$store.getters["article/getInterestGroup"];
    },
    type() {
      var topic;
      if (this.topicDetails.length < 2) {
        topic = 'Private'
      } else {
        topic = 'Public'
      }
      return topic;
    },
    users() {
      var users;
      if (this.type === 'Private') {
        users = this.topicDetails[0];
      } else {
        users = this.topicDetails[1];
      }
      return users
    },
    topicOptions(){
      return this.$store.getters["profile/getUserInterests"];
    },
    interestGroupId(){
      return this.$store.getters["article/getInterestGroupId"];
    },
    randomId() {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      return timestamp;
    }
  },
  methods: {
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchAllUsers(value) {
      try {
        this.contactOptions = [];
        this.onUsersRequest = true;
        this.debounce(async () => {
          const payload = {
            full_name: value,
          };
          const response = await this.$store.dispatch(
            "profile/getUsersList",
            payload
          );
          if (response.status == 200) {
            response.data.map(async (val) => {
              if (val.profile) {
                let userList = {
                  label: val.profile.full_name,
                  value: val.id,
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                this.contactOptions.push(userList);
                await this.$store.commit(
                  "profile/setContacts",
                  this.contactOptions
                );
                return userList;
              }
            });
            this.onUsersRequest = false;
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    fetchedSelection(val) {
      this.selectedConnections = val;
    },
    addAdmins() {
      this.emptyTagifyInput = false;
      const newUsers = this.selectedConnections.map((user) => {
        return {
          id: user.id,
          full_name: user.value,
          profile_picture: user.icon,
          email: user.email
        };
      });

      // change create a function for erwmoving duplicates and pass on the array to the function and return back
      const removingDuplicates = newUsers.filter(newUser => (
        !this.users.admins.some(existingUser => existingUser.id === newUser.id) &&
        !this.users.pending_invitations_admins.some(existingUser => existingUser.id === newUser.id)
      ));


      this.newAdmins = [...removingDuplicates];
      this.users.pending_invitations_admins = [...this.users.pending_invitations_admins, ...removingDuplicates];

      this.selectedConnections = [];
      this.emptyTagifyInput = true;
    },
    addEditors() {
      this.emptyTagifyInput = false;
      const newUsers = this.selectedConnections.map((user) => {
        return {
          id: user.id,
          full_name: user.value,
          profile_picture: user.icon,
          email: user.email
        };
      });

         // change create a function for erwmoving duplicates and pass on the array to the function and return back
      const removingDuplicates = newUsers.filter(newUser => (
        !this.users.editors.some(existingUser => existingUser.id === newUser.id) &&
        !this.users.pending_invitations_editors.some(existingUser => existingUser.id === newUser.id)
      ));
      this.newEditors = [...removingDuplicates]
      this.users.pending_invitations_editors = [...this.users.pending_invitations_editors, ...removingDuplicates];

      this.selectedConnections = [];
      this.emptyTagifyInput = true;
    },
    addContributors() {
      this.emptyTagifyInput = false;
      const newUsers = this.selectedConnections.map((user) => {
        return {
          id: user.id,
          full_name: user.value,
          profile_picture: user.icon,
          email: user.email
        };
      });

         // change create a function for erwmoving duplicates and pass on the array to the function and return back
      const removingDuplicates = newUsers.filter(newUser => (
        !this.users.contributors.some(existingUser => existingUser.id === newUser.id) &&
        !this.users.pending_invitations_contributors.some(existingUser => existingUser.id === newUser.id)
      ));
      this.newContributors = [...removingDuplicates]
      this.users.pending_invitations_contributors = [...this.users.pending_invitations_contributors, ...removingDuplicates];

      this.selectedConnections = [];
      this.emptyTagifyInput = true;
    },
    async sendInvitation() {
      try {
        this.loader = true;
        if (this.audienceChoice !== 'Change Type' || this.topicName !== this.topicToBeManage.name) {
            const changeTopicTypePaylaod = {
              id: this.topicToBeManage.id,
              interest: this.topicName,
              is_private: this.audienceChoice === 'private' ? true : false
            }
          const response = await this.$store.dispatch('profile/updateInterest', changeTopicTypePaylaod); 
          if (response.status === 200) {
            this.emitter.emit("change-topic-type");
            }
        }
        // REPLACE STATIC URL WITH DYNAMIC URL
        const adminInvitations = this.newAdmins.map(val => ({
          to_user: val.id,
          permission: 'admin',
          link: `http://staging.glocal.com/?topic=${this.topicName.replace(" ","-")}&id=${this.topicToBeManage.id}`
        }));

        // REPLACE STATIC URL WITH DYNAMIC URL
        const editorInvitations = this.newEditors.map(val => ({
          to_user: val.id,
          permission: 'editor',
          link: `http://staging.glocal.com/?topic=${this.topicName.replace(" ","-")}&id=${this.topicToBeManage.id}`
        }));

        const contributorInvitations = this.newContributors.map(val => ({
          to_user: val.id,
          permission: 'contributor',
          link: `http://staging.glocal.com/?topic=${this.topicName.replace(" ","-")}&id=${this.topicToBeManage.id}`
        }));

        const invitationList = [...adminInvitations, ...editorInvitations, ...contributorInvitations];

        const payload = {
          interest_group_id: this.interestGroupId,
          list: invitationList,
        };
        const response = await this.$store.dispatch("article/inviteOnTopic", payload);        

        if (response.status === 201) {
          this.$store.dispatch('profile/getUserTopicsWithOrdering');
          this.loader = false; 
        }
        } catch (err) {
          this.loader = false; 
          this.errorMsg = err.response.data.error;
      }
    },
    removeUser({ user, userType }) { 
      try {
        const response = this.$store.dispatch('article/removeAdminFromTopic', user);
        if (response.status === 200) {
          this.emitter.emit('user-card-loader',false)
        }
      } catch (err) {
        console.log(err);
      }
    } 
  }
};
</script>
<style lang="scss">
.tab-management-container {
  padding: 0 2.5rem;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 7.9rem);
  &__section {
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $color-dark-grey-5;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    &--msg {
      color: red;
    }
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      .title {
        font-size: 1.6rem;
        font-family: $font-primary-medium;
        line-height: 2.2rem;
        margin-bottom: 0;
        color: $color-black;
      }
      .btn {
        width: 11rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: $color-white;
        background-color: $color-primary;
        font-family: $font-primary;
        outline: none;
        border: none;
        border-radius: 5px !important;
        height: 3rem;
        margin-left: 1.4rem;
        cursor: pointer;
        .icon {
          margin-right: 0.7rem;
          font-size: 1rem;
          color: $color-white;
        }
      }
      .contributors-btn {
        width: 13rem;
      }
    }
    .privacy-settings {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        &--name {
          display: flex;
          align-items: center;
          img {
            width: 2rem;
            height: auto;
          }
          p {
            font-size: 1.4rem;
            font-family: $font-primary;
            line-height: 1.8rem;
            margin-bottom: 0;
            color: $color-black;
            margin-left: 0.6rem;
          }
        }
        &--select {
          border: none;
          outline: none;
          box-shadow: none;
          color: $color-white;
          opacity: 1;
          .ant-select-selector {
            background-color: $color-primary;
            color: $color-white !important;
            min-width: 11.5rem;
            height: 3.5rem !important;
            border-radius: 5px !important;
            border: none;
            outline: none !important;
            box-shadow: none !important;
            padding: 0 1.5rem;
            .ant-select-selection-item {
              height: 100%;
              display: flex;
              padding-right: 0rem;
              align-items: center;
              font-size: 1.2rem;
              font-family: $font-primary;
              .select-item {
                display: flex;
                img {
                  margin-top: -0.2rem;
                }
                .white-icon {
                  display: block !important;
                }
                .black-icon {
                  display: none !important;
                }
              }
            }
          }
          .ant-select-arrow {
            right: 1.5rem;
            width: 1rem;
            .anticon {
              font-size: 1rem;
              svg {
                fill: $color-white;
              }
            }
          }
        }
      }
      &__body {
        margin-top: 1.6rem;
        p {
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: 1.8rem;
          margin-bottom: 0;
          color: $color-black;
          opacity: 0.6;
          padding-right: 2rem;
        }
      }
    }
    &--search-user {
      position: relative;
      width: 100%;
      border: 1px solid #00000033;
      border-radius: 7px;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tagify {
        border: none;
        background-color: transparent;
        padding-left: 0.6rem;
        min-height: 4.6rem;
        max-height: 7.5rem;
        &__input {
          border: none;
          padding: 1.4rem 1.4rem 1.4rem 0.8rem;
          height: 4.6rem;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff3f;
        height: 2px;
      }
      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgb(224, 224, 224);
        border-radius: 3rem;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
    &--active-admin {
      margin-top: 2.6rem;
      .header {
        margin-bottom: 1.8rem;
        h2 {
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: 2.2rem;
          margin-bottom: 1.8rem;
          color: $color-black;
        }
      }
      .body {
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding-bottom: 1.5rem;
        &::-webkit-scrollbar-track {
          background-color: #ffffff3f;
          height: 2px;
        }
        &::-webkit-scrollbar {
          width: 3px;
          background-color: rgb(224, 224, 224);
          border-radius: 3rem;
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 3rem;
        }
        .message {
          font-size: 1.4rem;
          text-align: center;
          font-family: $font-primary;
          display: block;
          margin:  0 auto 2rem;
        }
      }
    }
    &--input {
      height: 4rem;
      padding: 1.2rem 1rem;
      border: 1px solid $color-dark-grey-5;
      outline: none;
      border-radius: 5px !important;
      width: 100%;
      font-size: 1.4rem;
      font-family: $font-primary;
      color: $color-black;
      &::placeholder {
        color: #949494;
      }
    }
  }
  &__footer {
    position: sticky;
    bottom: 0;
    padding: 1.5rem 0;
    background-color: $color-white;
    width: 100%;
    .apply-btn {
      border: none;
      outline: none;
      background: $color-primary;
      font-size: 1.6rem;
      color: $color-white;
      width: 15rem;
      height: 3.9rem;
      font-family: $font-primary-medium;
      border-radius: 100px !important;
      display: block;
      cursor: pointer;
      margin-left: auto;
      padding: 0 !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
      &:disabled {
        opacity: 0.2;
        cursor: default;
      }
      .anticon {
        width: 100%;
        border-radius: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 2.5rem;
        }
      }
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff3f;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgb(224, 224, 224);
    border-radius: 3rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 3rem;
  }
}
.public-topic-active {
  background-color: rgb(238, 238, 238) !important;
}
</style>
