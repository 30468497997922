<template>
    <div v-if="skeletonLoader">
        <a-skeleton
          class="rating-modal-skeleton"
          active
          :title="{ width: '100%' }"
          :paragraph="{
            rows: 6,
            width: ['30%', '60%', '100%', '30%', '60%', '100%'],
          }"
        />
    </div>
    <div v-else class="rate-article-modal-container">
      <div class="rate-article-modal-container__header">
        <h2 class="rate-article-modal-container__header--title">
          Rate this article
        </h2>
      </div>
      <div class="rate-article-modal-container__body">
        <div class="rate-article-modal-container__body--input-field-container"
          v-for="question in questions" :key="question.id">
          <p class="rate-article-modal-container__body--input-field-container--title">{{ question.question }}</p>
          <a-radio-group 
            v-if="question.question === 'Accuracy'"
            v-model:value="isAccuracyChecked"
          >
            <a-radio 
              v-for="choice in question.choices"
              :key="choice.id" :value="choice.id"
              :class="{
                'green': 'Accurate' === choice.choice || 'Credible' === choice.choice,
                'yellow': 'Somewhat Accurate' === choice.choice || 'Somewhat Credible' === choice.choice,
                'red': 'Lacks Accuracy' === choice.choice || 'Lacks Credibility' === choice.choice,
              }"
              @click="changeRating(question.question)"
             >{{ choice.choice }}</a-radio>
          </a-radio-group>
          <!-- <span class="rate-article-modal-container__body--input-field-container--error"
            v-if="accuracyErr && question.question === 'Accuracy'">
              {{ accuracyErr }}
          </span> -->
          
          <a-radio-group 
            v-else-if="question.question === 'Credibility'"
            v-model:value="isCredibilityChecked"
          >
            <a-radio 
              v-for="choice in question.choices"
              :key="choice.id" :value="choice.id"
              :class="{
                'green': 'Accurate' === choice.choice || 'Credible' === choice.choice,
                'yellow': 'Somewhat Accurate' === choice.choice || 'Somewhat Credible' === choice.choice,
                'red': 'Lacks Accuracy' === choice.choice || 'Lacks Credibility' === choice.choice,
              }"
              @click="changeRating(question.question)"
             >{{ choice.choice }}</a-radio>
             
          </a-radio-group>
          <!-- <span class="rate-article-modal-container__body--input-field-container--error"
            v-if="credibilityErr && question.question === 'Credibility'">
            {{ credibilityErr }}
          </span> -->
          <textarea
            name=""
            id=""
            rows="1"
            v-model="question.description"
            ref="textArea"
            placeholder="Write a justification for your ratin.."
            class="rate-article-modal-container__body--input-field-container--textarea"
          ></textarea>
          <!-- <span class="rate-article-modal-container__body--input-field-container--error" 
            v-if="accuracyFeedbackErr && question.question === 'Accuracy'">
            {{ accuracyFeedbackErr }}
          </span>
          <span class="rate-article-modal-container__body--input-field-container--error" 
            v-if="credibilityFeedbackErr && question.question === 'Credibility'">
            {{ credibilityFeedbackErr }}
          </span> -->
        </div>
        <span class="rate-article-modal-container__body--input-field-container--error" 
          v-if="errorMessage">
          {{ errorMessage }}
        </span>
        <a-button 
          v-if="this.questions[0].user_answer.is_answered"
          @click="clearRatings()" 
          :class="{'rate-article-modal-container__body--loading' : clearLoading }"
          class="rate-article-modal-container__body--clear-btn">
          Clear ratings
        </a-button>
        <a-button 
          :loading="loader" @click="
          submitRatings(questions)" 
          class="rate-article-modal-container__body--btn">
          Submit Ratings
        </a-button>
      </div>
    </div>
</template>

<script>


export default {
  data() {
    return {
      value: 1,
      skeletonLoader: false,
      // accuracyRating: {},
      // credibilityRating: {},
      // justificationType: "",
      ratingIds: {},
      questions: [],
      isAccuracyChecked: false,
      isCredibilityChecked: false,
      // accuracyFeedbackErr: null,
      // credibilityFeedbackErr: null,
      // accuracyErr: null,
      // credibilityErr: null,
      loader: false,
      clearLoading: false,
      errorMessage: null
    };
  },
  emits: ["handle-close"],
  props: {
    visible: Boolean,
    articleId: String
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getRatingQuestions();
          // this.emptyFiled();
        }
      },
      immediate: true
    }
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    async getRatingQuestions() {
      this.skeletonLoader = true;
      try {
        let payload = {
          articleId: this.articleId,
        };
        const response = await this.$store.dispatch(
          "discover/getQuestionRating",
          payload
        );
        this.skeletonLoader = false;
        this.questions = response.data;
        this.questions.forEach((ques) => {
          if (ques.question === "Accuracy") {
            this.isAccuracyChecked = ques.user_answer.rate;
          } else if (ques.question === "Credibility") {
            this.isCredibilityChecked = ques.user_answer.rate;
          }
        });
        this.ratingIds = {
          accurate: response.data[0].choices[0].id,
          somewhat_accurate: response.data[0].choices[1].id,
          lacks_accurate: response.data[0].choices[2].id,
          credible: response.data[1].choices[0].id,
          somewhat_credible: response.data[1].choices[1].id,
          lacks_credible: response.data[1].choices[2].id,
        };
        if (response.data[0].user_answer) {
          this.questions[0].description = response.data[0].user_answer.feedback;
        }
        if (response.data[1].user_answer) {
          this.questions[1].description = response.data[1].user_answer.feedback;
        }
      } catch (err) {
        console.log(err);
      }
    },
    changeRating(questionType) {
      if (questionType === "Accuracy") {
        this.isAccuracyChecked = !this.isAccuracyChecked;
      } else if (questionType === "Credibility") {
        this.isCredibilityChecked = !this.isCredibilityChecked;
      }
    },
    emptyFiled() {
      this.accuracyErr = null;
      this.credibilityErr = null;
      this.accuracyFeedbackErr = null;
      this.credibilityFeedbackErr = null;
    },
    async submitRatings(questions) {
      this.errorMessage = null;
      this.loader = true;
      if (!this.isCredibilityChecked && !this.isAccuracyChecked) {
        console.log(this.isCredibilityChecked, " Sec", this.isAccuracyChecked)
        this.errorMessage = "Please rate any one of them at least"
      }else if (!this.isCredibilityChecked) {
        this.errorMessage = "Please rate credibility"
      }else if (!this.isAccuracyChecked) {
        this.errorMessage = "Please rate accuracy"
      }
      // else if (this.isCredibilityChecked && !this.isAccuracyChecked && !this.questions[0].description ) {
      //   this.errorMessage = "Please provide a description"
      // } else if (this.isAccuracyChecked && !this.isCredibilityChecked && !this.questions[1].description) {
      //   this.errorMessage = "Please provide a description"
      // }
      try {
        if(!this.errorMessage){   
          const payload = []

          // if (this.questions[0]) {
            payload.push({
              feedback: this.questions[0].description,
              rating_question: questions[0].id,
              article: this.articleId,
              rate: this.isCredibilityChecked,
            });
          // }

          // if (this.questions[1]) {
            payload.push({
              feedback: this.questions[1].description,
              rating_question: questions[1].id,
              article: this.articleId,
              rate: this.isAccuracyChecked,
            });
          // }
          let apiCall =
          this.questions[0].user_answer.is_answered || this.questions[1].user_answer.is_answered ?
          "discover/updateRatings" : "discover/createRatings";
          const response = await this.$store.dispatch(apiCall, payload);
        if(response.status === 201 || response.status === 200){
          this.closeModal();
          const message = {
            visible: true,
            title: "Article Rated",
            type: "success",
          };
          this.emitter.emit('show-submit-rating-msg', message)
        }
      }
      
      } catch(err){
        console.log(err);
      } finally {
        this.loader = false;
      }
    },
    async clearRatings() {
      this.clearLoading = true;
      try {
        const payload = {
          article_id: this.$route.params.id
        }
        const response = await this.$store.dispatch('discover/deleteRatings', payload);
        if (response.status === 204) {
          this.clearLoading = false;
          this.closeModal();
          const message = {
            visible: true,
            title: "Rating deleted successfully",
            type: "success",
          };
          this.emitter.emit('show-submit-rating-msg', message)
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
};
</script>

<style lang="scss">
.rate-article-modal-container {
  &__header {
    padding: 3rem 2.2rem;
    &--title {
      font-family: $font-primary-bold;
      font-size: 2rem;
      margin-bottom: 0;
      line-height: normal;
      color: $color-black;
    }
  }
  &__body {
    padding: 0 2.2rem 3rem;
    &--input-field-container {
      margin-bottom: 2.5rem;
      &--title {
        font-family: $font-primary-medium;
        font-size: 1.6rem;
        margin-bottom: 0;
        line-height: normal;
        color: $color-black;
      }
      .ant-radio-group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.7rem;
        margin-bottom: 0.5rem;
        .ant-radio-wrapper {
          margin-right: 0;
          padding: 0.6rem 1.6rem;
          border-radius: 10rem;
          border: 1px solid $color-black;
          display: flex;
          align-items: center;
          .ant-radio {
            .ant-radio-inner {
              border: none;
              background-color: $color-white;
              width: 1.6rem;
              height: 1.6rem;
              &::after {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $color-primary;
              }
            }
          }
        }
        span.ant-radio + * {
          padding-right: 0;
          padding-left: 0.6rem;
          font-family: $font-primary;
          font-size: 1.2rem;
          color: $color-black;
          line-height: normal;
          margin-bottom: -0.1rem;
        }
        .green {
          background-color: rgba(16, 196, 13, 0.3);
          border-color: rgba(16, 196, 13, 1);
          .ant-radio {
            .ant-radio-inner {
              &::after {
                background-color: rgba(16, 196, 13, 1);
              }
            }
          }
        }
        .yellow {
          background-color: rgba(255, 217, 0, 0.3);
          border-color: rgba(255, 193, 100, 1);
          margin: 0 0.5rem;
          .ant-radio {
            .ant-radio-inner {
              &::after {
                background-color: rgba(255, 193, 100, 1);
              }
            }
          }
        }
        .red {
          background-color: rgba(236, 66, 66, 0.3);
          border-color: rgba(236, 66, 66, 1);
          .ant-radio {
            .ant-radio-inner {
              &::after {
                background-color: rgba(236, 66, 66, 1);
              }
            }
          }
        }
      }
      &--textarea {
        margin-top: 1.8rem;
        width: 100%;
        height: 4rem;
        padding: 1.2rem 1.4rem;
        outline: none;
        font-size: 1.4rem;
        font-family: $font-primary;
        line-height: 1.6rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        resize: none;
        overflow: -moz-hidden-unscrollable;
        &::placeholder {
          opacity: 0.5;
        }
      }
      &--textarea::-webkit-scrollbar {
        display: none;
      }
      &--error {
        font-family: $font-primary;
        font-size: 1.4rem;
        margin-bottom: 0;
        line-height: normal;
        color: red;
      }
    }
    &--loading {
      opacity: 0.5;
      cursor: pointer;
    }
    &--clear-btn {
      background-color: transparent;
      height: fit-content;
      line-height: normal;
      padding: 0;
      margin-left: auto;
      display: block;
      margin-top: -1.5rem;
      border: none;
      color: $color-black;
      font-size: 1.4rem;
      font-family: $font-primary-medium;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: $color-black;
      }
    }
    &--btn {
      margin-top: 5rem;
      color: $color-white;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      background-color: $color-primary;
      border: none;
      margin-left: auto;
      width: 14rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      border-radius: 0.5rem !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
      span {
        line-height: normal;
      }
      .loader {
        line-height: normal;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff94;
        cursor: auto;
        .ant-spin {
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          .anticon {
            font-size: 3rem !important;

            .anticon-spin {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  }
  .rating-modal-skeleton {
  .ant-skeleton-content {
    padding: 2rem 3rem;
    .ant-skeleton-title {
      display: none;
    }
    .ant-skeleton-paragraph {
      margin: 0;
      li {
        height: 2.5rem;
        margin: 2rem 0;
      }
    }
  }
  }
</style>
