<template>
  <div id="active-article-read" class="pdb-article-read-view">
    <div class="pdb-article-read-view__body">
      <a-row :gutter="[18, 18]">
        <a-col  :xs="4" :sm="3" :md="1" :lg="1" :xl="1" class="pdb-article-read-view__body--left">
          <div v-if="searchingArticles">
            <a-skeleton
              class="sidebar-skeleton"
              active
              :title="{ width: '100%' }"
            />
          </div>
          <readview-side-bar
            v-else
            :articleId="id"
            :title="title"
            :image="imageUrl"
            :source="source"
            :url="url"
            :date="date"
            :userReaction="userReaction"
          ></readview-side-bar>
        </a-col>
        <a-col :xs="24" :sm="24" :md="23" :lg="23" :xl="23" class="pdb-article-read-view__body--right px-sm-0">
          <a-row :gutter="[18, 18]">
            <a-col :xs="24" :sm="24" :md="16" :lg="18" :xl="18" class="px-sm-0">
              <div v-if="searchingArticles">
                <a-skeleton
                  class="article-view-card-skeleton"
                  active
                  :title="{ width: '100%' }"
                  :paragraph="{
                    rows: 6,
                    width: ['100%', '100%', '100%', '100%', '100%', '50%'],
                  }"
                />
              </div>
              <div v-else class="read-acticle-container">
                <div class="read-acticle-container--header">
                  <div class="steps">
                    <h2>
                      <span class="topic">{{ topic }} ></span>
                      <span class="title">{{ title }}</span>
                    </h2>
                  </div>
                </div>
                <div class="read-acticle-container--body">
                  <div class="article-view-card">
                    <div
                      class="article-view-card__header"
                      :style="{
                        backgroundImage: `linear-gradient(179.9deg, rgba(0, 0, 0, 0) 0.09%, #000000 90.3%), url(${imageUrl})`,
                      }"
                    >
                      <h2 class="article-view-card__header--title">
                        <a :href="url" target="_blank">{{ title }}</a> 
                      </h2>
                      <div class="article-view-card__header--auth-and-rating">
                        <div class="auth">
                          <img
                            v-if="sourceLogo"
                            :src="sourceLogo"
                            alt="icon"
                          />
                          <img v-else
                            class="logo-image"
                            src="@/assets/icons/newspaper.svg"/>
                          <p>
                            {{ authorName }},
                            <span class="source"><a :href="url" target="_blank">{{ source }}</a></span> |
                            <span class="date">Publish date: {{ date }}</span>
                          </p>
                        </div>
                        <button class="rating" @click="visibleRateArticleModal()">
                          <img src="@/assets/icons/data_exploration.svg" alt="icon" />
                          Rate this Article
                        </button>
                      </div>
                    </div>
                    <div class="article-view-card__readview-container">
                      <readview-side-bar
                        :articleId="id"
                        :title="title"
                        :image="imageUrl"
                        :source="source"
                        :url="url"
                        :date="date"
                        :userReaction="userReaction"
                      ></readview-side-bar>
                    </div>
                    <div class="article-view-card__body">
                      <div v-if="htmlContent !== 'false'" class="article-view-card__body--description">
                        <h4 v-html="htmlContent"></h4>
                      </div>
                      <div v-else class="article-view-card__body--description">
                        <h4>{{ text }} </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col id="active-insights" :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
              <div class="insights-section">
                <div class="insights-section__header">
                  <h4>Related Insights</h4>
                </div>
                <div class="insights-section__body">
                  <insight-view> </insight-view>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="pdb-article-read-view__footer">
      <more-articles-section></more-articles-section>
    </div>
    <!-- <discussions-collapse
    :isArticleReadview="true"
    :articleId="this.id"
    >
    </discussions-collapse> -->
    <topic-discussion
      :isArticleView="true"
    ></topic-discussion>
  </div>
  <!-- Rate article modal -->
  <a-modal
    v-model:visible="showRateArticleModal"
    :footer="null"
    wrapClassName="rate-article-modal"
  >
    <article-rating-modal
      :visible="showRateArticleModal"
      :articleId="id"
      @handle-close="closeRateArticleModal"
    >
    </article-rating-modal>
  </a-modal>
  <message-toaster
      :visible="showMessageModal"
      :message="message"
      @handle-close="showMessageModal = false"
    >
  </message-toaster>

</template>

<script>
import ReadviewSideBar from "./ReadviewSideBar.vue";
import MoreArticlesSection from "../PdbComponents/MoreArticlesSection.vue";
import InsightView from "../PdbComponents/InsightView.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import TopicDiscussion from '../BaseComponents/commentsSection/TopicDiscussion.vue';
import { mapActions } from "vuex/dist/vuex.cjs.js";
// import DiscussionsCollapse from "../BaseComponents/commentsSection/DiscussionsCollapse.vue";
import ArticleRatingModal from '../BaseComponents/ArticleRatingModal.vue';

export default {
  components: {
    MoreArticlesSection,
    ReadviewSideBar,
    InsightView,
    MessageToaster,
    TopicDiscussion,
    ArticleRatingModal
    // DiscussionsCollapse,
  },
  data() {
    return {
      title: "",
      authorName: "",
      text: "",
      imageUrl: "",
      sourceLogo: "",
      source: "",
      url: "",
      id: "",
      date: "",
      searchingArticles: false,
      userReaction: {},
      showMessageModal: false,
      message: {},
      htmlContent: "",
      activeNewsTab: true,
      showRateArticleModal: false,
    }
  },
  computed: {
    topic(){
      return this.$store.getters['discover/getTopicRoute'] ? this.$store.getters['discover/getTopicRoute'].replace(/-/g, ' ') : "";
    },
  },
  mounted(){
    window.scrollTo(0,0);
    this.emitter.on('show-submit-rating-msg', (message)=>{
      this.showMessageModal = message.visible;
      this.message = {
        title: message.title,
        type: message.type,
      }
    });
    window.addEventListener('scroll', (val)=> {
      const section = document.getElementById(`active-insights`);
      if(section.offsetTop > scrollY){
        this.activeNewsTab = true;
      }
      else if(section.offsetTop < scrollY){
        this.activeNewsTab = false;
      }

    });
  },
  unmounted(){
    this.setSelectedDiscussionArticleId(null)
    },
  watch: {
    $route: {
      handler(to) {
        if (to.params.articleId) {
          this.getArticle(to.params.articleId);
          this.$store.commit('discover/SET_TOPIC_ROUTE', to.params.topic);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSelectedDiscussionArticleId:'discussion/setSelectedDiscussionArticleId'
    }),
    async getArticle(id) {
      this.searchingArticles = true;
      const response = await this.$store.dispatch(
        "article/getNotificationsArticleData",
        id
      );
      if (response.status === 200) {
          this.title = response.data.title,
          this.authorName = response.data.authors,
          this.text = response.data.description,
          this.imageUrl = response.data.image,
          this.sourceLogo = response.data.logo,
          this.source = response.data.article_url.source,
          this.url = response.data.article_url.url,
          this.id = response.data.id,
          this.date = response.data.pub_date,
          this.userReaction = response.data.user_reaction,
          this.searchingArticles = false;
          this.htmlContent = response.data.html;
      }
      this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
      await this.$store.dispatch("discover/fetchDiscoverArticleComments",{'article_id':this.id});
      this.setSelectedDiscussionArticleId(this.id)
      await this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
    },
    visibleRateArticleModal() {
      this.showRateArticleModal = true;
    },
    closeRateArticleModal() {
      this.showRateArticleModal = false;
      this.showRateArticleDrawer = false
    },
  },
};
</script>

<style lang="scss">
.pdb-article-read-view {
  height: fit-content;
  position: relative;
  // padding-bottom: 8rem;
  &__body {
    padding: 0 2rem;
    position: relative;
    @include respond(tab-port) {
      padding: 0 2rem;
      .px-sm-0 {
        padding: 0 !important;
      }
    }
    &--left {
      display: block !important;
      @include respond(tab-port) {
        display: none !important;
      }
    }
    .read-acticle-container {
      position: relative;
      height: 100%;
      margin-left: 19px;
      @include respond(tab-port) {
        margin-left: 0;
      }
      &--header {
        position: sticky;
        top: 0;
        padding: 3.3rem 0 2rem;
        z-index: 2;
        background-color: $color-white;
        @include respond(tab-port) {
          display: none;
        }
        .steps {
          display: flex;
          align-items: center;
          h2 {
            font-size: 1.8rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2rem;
            display: flex;
            align-items: center;
            width: 100%;
            color: $color-black;
            @include respond(laptop-large) {
              font-size: 1.6rem;
            }
            @include respond(phone-x-small) {
              font-size: 1.2rem;
            }
            .title {
              margin: 0 0.4rem;
              text-overflow: ellipsis;
              max-width: 20rem;
              height: 2rem;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      &--body {
        .article-view-card {
          margin: 0 0 2rem;
          &__header {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            height: 100%;
            max-height: 40rem;
            min-height: 40rem;
            border-top-left-radius: 1rem !important;
            border-top-right-radius: 1rem !important;
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: inherit;
            background-position-y: inherit;
            &--title {
              font-size: 2.4rem;
              font-family: $font-primary-bold;
              line-height: 3.3rem;
              color: $color-white;
              font-weight: 700;
              margin-bottom: 0;
              padding: 0 2.3rem;
              padding-right: 5rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
              a {
                color: $color-white;
                &:hover {
                  color: $color-white;
                  text-decoration: underline;
                }
              }
            }
            &--auth-and-rating {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.8rem 2.3rem;
              width: 100%;
              .auth {
                display: flex;
                align-items: center;
                img {
                  width: 3.7rem;
                  height: 3.7rem;
                  border-radius: 100%;
                  margin-right: 1rem;
                  background-color: #d9d9d9;
                }
                .logo-image {
                  object-fit: contain;
                  border-radius: 0;
                  background-color: transparent;
                  width: 2rem;
                  height: 2rem;
                }
                p {
                  font-size: 1.2rem;
                  font-family: $font-primary;
                  line-height: 1.6rem;
                  color: $color-white;
                  margin-bottom: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  .source {
                    text-transform: uppercase;
                    a {
                      color: $color-white;
                      &:hover {
                        color: $color-white;
                        text-decoration: underline;
                      }
                    }
                  }
                  .date {
                    color: #b6b6b6;
                  }
                }
              }
              .rating {
                display: flex;
                align-items: center;
                height: 4.4rem;
                background-color: $color-white;
                color: $color-black;
                font-size: 1.6rem;
                border: none;
                outline: none;
                border-radius: 1rem !important;
                font-family: $font-primary-medium;
                padding: 0 2rem;
                cursor: pointer;
                img {
                  width: 2rem;
                  margin-right: 1rem;
                }
              }
              @include respond(tab-port) {
                .rating {
                  display: none;
                }
              }
            }
          }
          &__readview-container {
            display: none;
            @include respond(tab-port) {
              display: block;
              position: sticky;
              top: 8rem;
            }
            @include respond(phone-x-small) {
              position: sticky;
              top: 10.8rem;
            }
            .read-view-sidebar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 2.2rem 2.8rem;
              border-bottom: 1px solid #0000004D;
              &__button {
                margin-bottom: 0;
                &--btn {
                  width: 7rem;
                  height: 7rem;
                  margin-bottom: 0.7rem;
                  img {
                    width: 2.3rem;
                  }
                }
                &--title {
                  font-size: 2.1rem;
                }
                .the-verticle-reactions-bar {
                  &__reaction-status {
                    &--reactions-container {
                      .btn {
                        width: 7rem;
                        height: 7rem;
                        margin-bottom: 0.7rem;
                        img {
                          width: 2.3rem;
                        }
                      }
                      .title {
                        font-size: 2.1rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &__body {
            &--description {
              @include respond(tab-port) {
                padding: 2.2rem 4.4rem;
                background-color: #f3f3f3;
              }
              h4 {
                font-size: 1.8rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 2.7rem;
                margin: 2rem 0;
                text-align:justify;
              }
            }
          }
        }
      }
    }
    .article-view-card-skeleton {
      margin: 0 9px;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 40rem;
          border-radius: 1rem;
        }
        .ant-skeleton-paragraph {
          li {
            height: 2rem;
            margin: 2rem 0;
          }
        }
      }
    }
    .insights-section {
      height: 100%;
      &__header {
        padding-top: 3rem;
        position: sticky;
        background-color: white;
        z-index: 22;
        top: 0;
        padding-bottom: 2rem;
        margin-bottom: 0;
        @include respond(tab-port) {
          padding: 2.2rem 4rem;
        }
        h4 {
          position: relative;
          font-size: 2.4rem;
          font-family: $font-primary-medium;
          font-weight: 700;
          margin-bottom: 0;
          line-height: normal;
          &::after {
            content: "";
            width: 4rem;
            height: 0.2rem;
            background-color: #0033dd;
            position: absolute;
            bottom: -0.7rem;
            left: 0;
          }
        }
      }
      &__body {
        max-height: 160rem;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: $color-primary rgba(255, 255, 255, 0);
        scrollbar-width: thin;
        @include respond(tab-port) {
          padding: 2.2rem 4rem;
        }
      }
      &__body::-webkit-scrollbar {
        width: 0.5rem;
      }

      &__body::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 0.8rem;
      }

      &__body::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 0.8rem;
        height: 5rem;
      }
    }
    .sidebar-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 44rem;
          border-radius: 1rem;
        }
        .ant-skeleton-paragraph {
          display: none;
        }
      }
    }
  }
  &__footer {
  }
  .discussion-collapse-container {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding-top: 0;
            .comments-section {
              border-top: none !important;
            }
          }
        }
      }
    }
  }
}
.rate-article-modal {
  .ant-modal {
    width: 55rem !important;
    .ant-modal-content {
      background-color: rgb(246, 248, 255);
    }
  }
}
</style>
