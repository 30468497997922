<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="visibleDrawer"
    wrapClassName="sources-filter-drawer"
    width="46.5rem"
    title="Topic Filters"
    @close="$emit('close-drawer')"
  >
    <div class="sources-filter-drawer-container">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1">
          <template #tab>
            <span class="tabs-name">News Sources</span>
          </template>
          <div class="sources-filter-drawer-container-body">
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Key words to include</p>
              <pdb-words-filter
                :type="'include'"
                @fetchInlcude="getPDBIncludeKeywords"
              ></pdb-words-filter>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Key words to exclude</p>
              <pdb-words-filter
                :type="'exclude'"
                @fetchExclude="getPDBExcludeKeywords"
              ></pdb-words-filter>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Date Range</p>
              <pdb-date-filters> </pdb-date-filters>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Included Sources</p>
              <pdb-news-sources
                :source="'include'"
                :closeDropDown="closeDropDown"
              ></pdb-news-sources>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Excluded Sources</p>
              <pdb-news-sources
                :source="'exclude'"
                :closeDropDown="closeDropDown"
              ></pdb-news-sources>
            </div>
          </div>
          <div class="sources-filter-drawer-container-footer">
            <button @click="clearNewsFilters()" class="sources-filter-drawer-container-footer__btn sources-filter-drawer-container-footer__clear-filter-btn">
              Clear Filter
            </button>
            <button @click="searchNewsArticles()" class="sources-filter-drawer-container-footer__btn">
              Apply Filter
            </button>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <span class="tabs-name">Research Publications</span>
          </template>
          <div class="sources-filter-drawer-container-body">
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Key words to include</p>
              <insights-words-filter
                :type="'include'"
                @fetchInlcude="getInsightsIncludeKeywords"
              ></insights-words-filter>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Key words to exclude</p>
              <insights-words-filter
                :type="'exclude'"
                @fetchExclude="getInsightsExcludeKeywords"
              ></insights-words-filter>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Date Range</p>
              <insights-date-filters> </insights-date-filters>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Included Sources</p>
              <insights-news-sources
                :source="'include'"
                :closeDropDown="closeDropDown"
              >
              </insights-news-sources>
            </div>
            <div class="sources-filter-drawer-container-body__input-field">
              <p class="title">Excluded Sources</p>
              <insights-news-sources
                :source="'exclude'"
                :closeDropDown="closeDropDown"
              >
              </insights-news-sources>
            </div>
          </div>
          <div class="sources-filter-drawer-container-footer">
            <button @click="clearInsightsFilters()" class="sources-filter-drawer-container-footer__btn sources-filter-drawer-container-footer__clear-filter-btn">
              Clear Filter
            </button>
            <button @click="searchInsightsArticles()" class="sources-filter-drawer-container-footer__btn">
              Apply Filter
            </button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-drawer>
</template>

<script>
import PdbWordsFilter from "./PDBWordsFilter.vue";
import InsightsWordsFilter from "./InsightsWordsFilter.vue";
import PdbNewsSources from "./PDBNewsSources.vue";
import InsightsNewsSources from "./InsightsNewsSources.vue";
import PdbDateFilters from "./dateFilters/PDBDateFilters.vue";
import InsightsDateFilters from "./dateFilters/InsightsDateFilters.vue";

export default {
  emits: ["close-drawer"],
  props: {
    visibleDrawer: Boolean,
  },
  components: {
    PdbNewsSources,
    PdbDateFilters,
    InsightsDateFilters,
    InsightsNewsSources,
    PdbWordsFilter,
    InsightsWordsFilter
  },
  data() {
    return {
      activeKey: '1',
      closeDropDown: false,
    }
  },
  computed: {
    getSelectedNewIncludeSources() {
      return this.$store.getters['discover/getIncludedSelectedSources']; 
    },
    getSelectedNewsExcludeSources() {
      return this.$store.getters['discover/getExcludedSelectedSources'];
    },
    getSelectedInsightsIncludeSources() {
      return this.$store.getters['discover/getInsightsIncludedSelectedSources'];
    },
    getSelectedInsightsExcludeSources() {
      return this.$store.getters['discover/getInsightsExcludedSelectedSources'];
    },
    // PDBIncludeKeywords() {
    //   return this.$store.getters['discover/getPDBIncludedKeywords']; 
    // },
    // PDBExcludeKeywords() {
    //   return this.$store.getters['discover/getPDBExcludedKeywords'];
    // },
    // insightsIncludeKeywords() {
    //   return this.$store.getters['discover/getInsightsIncludedKeywords'];
    // },
    // insightsExcludeKeywords() {
    //   return this.$store.getters['discover/getInsightsExcludedKeywords'];
    // },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'Escape') {
        if (this.activeKey === '1' &&
          Object.keys(this.getSelectedNewIncludeSources).length > 0 ||
          Object.keys(this.getSelectedNewsExcludeSources).length > 0) {
          this.searchNewsArticles();
        } else if (this.activeKey === '2' &&
          Object.keys(this.getSelectedInsightsIncludeSources).length > 0 ||
          Object.keys(this.getSelectedInsightsExcludeSources).length > 0) {
          this.searchInsightsArticles();
        }
      }
    });
  },
  methods: {
    async searchNewsArticles() {
      this.closeFilterDrawer();
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      this.closeFilterDrawer();
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      await this.$store.dispatch("discover/searchInsights");
    },
    clearInsightsFilters() {
      this.emitter.emit("clearInsightsFilters", true);
      this.emitter.emit()
      this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", "");
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllSources");
      this.searchInsightsArticles();
    },
    clearNewsFilters() {
      this.emitter.emit("clearNewsFilters", true);
      this.$store.commit("discover/SET_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_EXCLUDED_SOURCES", "");
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllInsightsSources");
      this.searchNewsArticles();
    },
    closeFilterDrawer() {
      this.emitter.emit("closeFilterSideDrawer", false);
    },
    getPDBIncludeKeywords(val) {
      const keywords = val.map(words => `"${words}"`).join(' ');
      console.log(keywords);
      this.$store.commit('discover/PDB_INCLUDED_KEYWORDS', keywords);
    },
    getPDBExcludeKeywords(val) {
      const keywords = val.map(country => `-${country}`).join(' ');
      console.log(keywords);
      this.$store.commit('discover/PDB_EXCLUDED_KEYWORDS', keywords);
    },
    getInsightsIncludeKeywords(val) {
      const keywords = val.map(words => `"${words}"`).join(' ');
      console.log(keywords);
      this.$store.commit('discover/INSIGHTS_INCLUDED_KEYWORDS', keywords);
    },
    getInsightsExcludeKeywords(val) {
      const keywords = val.map(country => `-${country}`).join(' ');
      console.log(keywords);
      this.$store.commit('discover/INSIGHTS_EXCLUDED_KEYWORDS', keywords);
    },
  }
};
</script>

<style lang="scss">
.sources-filter-drawer {
  @include reset-css;
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
          .sources-filter-drawer-container {
            position: relative;
            height: 100vh;
            .ant-tabs {
              height: 100%;
              padding: 0 1.5rem;
              .ant-tabs-bar {
                border: none;
                margin-bottom: 2.6rem;
                .ant-tabs-nav-container {
                  padding-bottom: 2px;
                  .ant-tabs-tab-arrow-show {
                    display: none !important;
                  }
                  .ant-tabs-nav-wrap {
                    border: none;
                    .ant-tabs-nav-scroll {
                      border: none;
                      overflow-x: auto;
                      margin: 0 1rem;
                      &::-webkit-scrollbar {
                        height: 0.3rem;
                      }
                      &::-webkit-scrollbar-track {
                        background-color: $color-white;
                        border-radius: 0.8rem;
                        height: 0.3rem;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 0.8rem;
                      }
                      @include respond(phone-x-small) {
                        &::-webkit-scrollbar {
                          display: none;
                        }
                      }
                      .ant-tabs-nav {
                        border: none;
                        padding-bottom: 0;
                        .ant-tabs-tab {
                          padding: 0.6rem 2rem;
                          border-radius: 100px;
                          font-size: 1.4rem;
                          border: 1px solid $color-dark-grey-5;
                          font-family: $font-primary;
                          color: #434343;
                          margin-right: 1.2rem;
                          margin-bottom: 0.2rem;
                        }
                        .ant-tabs-tab-active {
                          font-family: $font-primary-regular;
                          background-color: $color-dark-grey-5;
                          color: $color-black;
                          opacity: 1;
                        }
                        .ant-tabs-ink-bar {
                          display: none;
                        }
                      }
                      .ant-tabs-ink-bar {
                        display: none !important;
                      }
                    }
                  }
                }
              }
              .ant-tabs-content {
                .ant-tabs-tabpane {
                  height: 100%;
                  overflow-x: hidden;
                  .sources-filter-drawer-container-body {
                    padding: 0 1rem;
                    overflow-y: auto;
                    height: calc(100vh - 22.5rem);
                    overflow-x: hidden;
                    &__input-field {
                      margin-bottom: 3rem;
                      .title {
                        font-size: 1.4rem;
                        color: $color-black;
                        font-family: $font-primary-medium;
                        font-weight: 500;
                        margin-bottom: 1.6rem;
                        line-height: 1.6rem;
                        width: fit-content;
                      }
                      input {
                        border-radius: 5px !important;
                        font-size: 1.4rem;
                        border: 1px solid #c5c6cc;
                        font-family: $font-primary;
                        width: 100%;
                        height: 4rem;
                        padding: 1rem;
                        outline: none;
                        color: $color-black;
                        &::placeholder {
                          opacity: 0.4;
                          color: $color-black;
                        }
                      }
                      .all-news-sources-dropdown {
                        &__dropdown {
                          border: 1px solid #d6d6d6;
                          border-top: none;
                        }
                        &__selected-sources {
                          margin-top: 2rem;
                          p {
                            color: $color-black;
                          }
                          .message {
                            opacity: 0.8;
                          }
                          &--select-container {
                            max-height: 100%;
                          }
                        }
                      }
                      .pdb-news-sources-date-filters,
                      .pdb-reserch-date-filters {
                        .end-date {
                          margin-bottom: 0;
                        }
                      }
                    }
                    &::-webkit-scrollbar-track {
                      background-color: #ffffff3f;
                    }
                    &::-webkit-scrollbar {
                      width: 4px;
                      background-color: rgb(224, 224, 224);
                      border-radius: 3rem;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: $color-primary;
                      border-radius: 3rem;
                    }
                  }
                  .sources-filter-drawer-container-footer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 2rem 0;
                    &__btn {
                      border: none;
                      outline: none;
                      background-color: $color-primary;
                      font-size: 1.6rem;
                      color: $color-white;
                      width: max-content;
                      padding: 0 4rem;
                      height: 3.9rem;
                      font-family: $font-primary-medium;
                      border-radius: 100px !important;
                      margin-left: 0;
                      cursor: pointer;
                      display: block;
                    }
                    &__clear-filter-btn {
                      background-color: $color-dark-grey-5;
                      color: $color-black;
                      margin-right: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
